<template>
  <div class="page" :style="{minHeight:cHeight}" ref="serverHeight">
    <sideBarTwo></sideBarTwo>
    <div class="box">
      <div>
        <div class="info">
          <div class="avator">
            <img :src="user.MemberPersonalPic?user.MemberPersonalPic:require('../assets/icon_head82.png')" alt="">
            <div>{{user.MemberName}}</div>
          </div>
          <div class="phone">{{MemberMoblie}}</div>
          <img v-if="user.AssociatorState=='已开通'" class="grey" src="../assets/personal_icon_vip_lighten.png" alt="">
          <img v-else class="grey" src="../assets/personal_icon_vip_grey.png" alt="">

          <div v-if="user.AssociatorState=='已开通'" class="isvips">
            会员有效期至：{{user.AssociatorTimeEnd?user.AssociatorTimeEnd.substring(0,10):''}}</div>
          <div v-else class="isvips">暂未开通会员</div>

        </div>
        <div class="buy">
          <div class="btn">
            <span v-if="user.AssociatorState=='未开通'" @click="getPage">开通会员</span>
            <span v-else @click="getPage">续费会员</span>
            <div v-if="user.AssociatorState=='未开通'">首月仅38元</div>
          </div>
        </div>
      </div>
      <div class="nav">
        <div class="top_nav">
          <div :class="{selected:selectIndex==1}" @click="choose(1)">个人资料</div>
          <div :class="{selected:selectIndex==2}" @click="choose(2)">我的订单</div>
          <div :class="{selected:selectIndex==3}" @click="choose(3)">会员记录</div>
          <div :class="{selected:selectIndex==4}" @click="choose(4)">合同模板</div>
          <div :class="{selected:selectIndex==5}" @click="choose(5)">优惠券</div>
        </div>
        <info v-if="selectIndex==1" @upDateInfo="upDateInfo"></info>
        <serverOrder v-if="selectIndex==2"></serverOrder>
        <record v-if="selectIndex==3"></record>
        <mouldboard v-if="selectIndex==4"></mouldboard>
        <coupon v-if="selectIndex==5"></coupon>
      </div>
    </div>
  </div>
</template>
<script>
  import sideBarTwo from '@/components/sideBarTwo'
  import info from '@/components/info'
  import serverOrder from '@/components/serverOrder'
  import record from '@/components/record'
  import mouldboard from '@/components/mouldboard'
  import coupon from '@/components/coupon'
  export default {
    data() {
      return {
        cHeight: null,
        selectIndex: 1,
        user: {
          AssociatorState: null,
          AssociatorTimeEnd: null,
          MemberAge: null,
          MemberArea: null,
          MemberCity: null,
          MemberEmail: null,
          MemberGuid: null,
          MemberHeadPic: null,
          MemberMoblie: null,
          MemberName: null,
          MemberPersonalPic: null,
          MemberProvince: null,
          MemberRealName: null,
          MemberSex: null,
          MemberThirdId: null,
        },
        MemberMoblie: null
      }
    },

    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      if (this.$route.query.id) {
        this.selectIndex = this.$route.query.id
      }
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.upDateInfo()
      this.MemberMoblie = this.geTel(this.user.MemberMoblie)
      _hmt.push(['_trackPageview', '/center'])
    },
    methods: {
      upDateInfo() {
        this.isMember().then(res => {
          this.user = localStorage.getItem('user') && localStorage.getItem('user') != undefined ? JSON.parse(
            localStorage.getItem('user')) : null
        })

      },
      getPage() {
        _hmt.push(['_trackEvent', 'click', '开通/续费', 'pc']);
        this.$router.push('/member')
      },
      geTel(tel) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        if (typeof tel === 'number') {
          tel = tel.toString()
        }
        return tel.replace(reg, "$1****$2");
      },
      choose(e) {
        if (e == 1) {
          _hmt.push(['_trackEvent', 'click', '个人资料', 'pc']);
        }
        if (e == 2) {
          _hmt.push(['_trackEvent', 'click', '我的订单', 'pc']);
        }
        if (e == 3) {
          _hmt.push(['_trackEvent', 'click', '开通记录', 'pc']);
        }
        if (e == 4) {
          _hmt.push(['_trackEvent', 'click', '合同模板', 'pc']);
        }
        if (e == 5) {
          _hmt.push(['_trackEvent', 'click', '优惠券', 'pc']);
        }
        this.selectIndex = e
        this.$nextTick(res => {
          setTimeout(res => {
            this.cHeight = this.$refs.serverHeight.offsetHeight + 'px'
          }, 1500)
        })
      }
    },
    components: {
      sideBarTwo,
      info,
      serverOrder,
      record,
      mouldboard,
      coupon
    }
  }

</script>
<style scoped lang="scss">
  .page {
    background: #F3F3F3;
  }

  .box {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    width: 940px;
  }

  .info {
    width: 220px;
    height: 340px;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;

    .avator {
      padding: 40px 0 0;
      height: 163px;
      text-align: center;
      background-image: url('../assets/bg_personal163.png');
      background-repeat: no-repeat;
      background-size: 100%;
      box-sizing: border-box;

      img {
        border-radius: 50%;
        width: 82px;
        height: 82px;
      }

      div {
        font-size: 18px;
        color: #000000;
        text-align: center;
      }
    }

    .phone {
      color: #666666;
      font-size: 16px;
      text-align: center;
    }

    .grey {
      display: block;
      margin: 40px auto 10px;
      width: 55px;
      height: 50px;
    }

    .isvips {
      color: #666666;
      font-size: 16px;
      text-align: center !important;
    }
  }

  .buy {
    padding-top: 30px;
    margin-top: 20px;
    width: 220px;
    height: 510px;
    background-image: url('../assets/personal_img_vip_ad.png');
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;

    .btn {
      position: relative;
      margin: auto;
      width: 150px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      color: #53300C;
      background: linear-gradient(-8deg, #E2AB75 0%, #FFD8A2 100%);
      border-radius: 20px;
      cursor: default;

      div {
        position: absolute;
        top: -13px;
        right: -17px;
        width: 75px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #FFFFFF;
        background: #F24537;
        border-radius: 10px 0px 10px 0px;
      }
    }
  }

  .nav {
    width: 700px;
    // min-height: 700px;
    background: #FFFFFF;
    border-radius: 20px;

    .top_nav {
      display: flex;
      padding: 0 30px;
      justify-content: space-around;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 60px;
      box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.0600);
      box-sizing: border-box;

      div {
        width: 128px;
        line-height: 70px;
        text-align: center;
        color: #666666;
        font-size: 14px;
        border-bottom: 4px solid transparent;
        cursor: default;
      }

      .selected {
        color: #21D0C8;
        border-color: #21D0C8;
      }
    }
  }

</style>
