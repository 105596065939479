<template>
  <div style="cursor: default;margin-top:50px;min-height:700px">
    <div class="list">
      <span class="title">头像</span>
      <img class="avator"
        :src="userInfo.MemberPersonalPic?userInfo.MemberPersonalPic:require('../assets/icon_head82.png')" alt="">
      <el-upload class="avatar-uploader" action="http://47.104.161.85:8014/api/v3/UserCenter/UpdatePersonalPic"
        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
        :data="{Sort:'kh',MemberGuid:user?user.MemberGuid:''}">
        <div class="change">修改</div>
      </el-upload>
    </div>
    <div class="list">
      <span class="title">昵称</span>
      <el-input v-model="userInfo.MemberName" autocomplete="off" style="width:200px" maxlength="10"></el-input>
    </div>
    <div class="list">
      <span class="title">性别</span>
      <img class="options" :src="gender===1?selectedSrc:selectSrc" alt="" @click="gender=1">
      <span class="gender" style="margin-right:40px">男</span>
      <img class="options" :src="gender===2?selectedSrc:selectSrc" alt="" @click="gender=2">
      <span class="gender">女</span>
    </div>
    <div class="list">
      <span class="title">年龄</span>
      <el-select v-model="ages" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="list">
      <span class="title">邮箱</span>
      <el-input v-model="userInfo.MemberEmail" autocomplete="off" style="width:300px"></el-input>
    </div>
    <div class="list">
      <span class="title">地区</span>
      <el-select v-model="province" placeholder="请选择省" @change="addOption" style="margin-right:10px;width:140px"
        clearable>
        <el-option v-for="item in provinces" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-select v-model="city" placeholder="请选择市" @change="addOptionTwo" style="margin-right:10px;width:140px"
        clearable>
        <el-option v-for="item in citys" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-select v-model="area" placeholder="请选择区/县" style=";width:140px" clearable>
        <el-option v-for="item in areas" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="save" @click="submit">保存</div>
  </div>
</template>

<script>
  import cityData from "@/utils/city"
  import {
    UpdateUserInfo,
    GetPersonalInfo
  } from '@/api'
  export default {
    data() {
      return {
        selectSrc: require('../assets/icon_gender_normal.png'),
        selectedSrc: require('../assets/icon_gender_selected.png'),
        gender: 1,
        pass: '',
        options: ['00后', '90后', '80后', '70后', '60后', '夕阳红'],
        value: '',
        provinces: [],
        citys: [],
        areas: [],
        province: '',
        city: '',
        area: '',
        user: null,
        ages: '',
        userInfo: {
          MemberAge: 0,
          MemberArea: null,
          AssociatorState: null,
          AssociatorTimeEnd: null,
          MemberCity: null,
          MemberEmail: null,
          MemberPersonalPic: null,
          MemberName: null,
          MemberProvince: null,
          MemberSex: null,
          MemberMoblie: null,
        },
      }
    },
    created() {
      this.getPersonalInfo()
    },
    mounted() {
      let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      })
      this.user = user
      this.allCity()
    },
    methods: {
      allCity() {
        for (let i = 0; i < cityData.length; i++) {
          this.provinces.push(cityData[i].name)
        }
        this.addOption()
      },
      addOption() {
        this.citys = []
        this.areas = []
        this.city = ''
        this.area = ''
        for (let i = 0; i < cityData.length; i++) {
          if (cityData[i].name == this.province) {
            for (let j = 0; j < cityData[i]['sub'].length; j++) {
              this.citys.push(cityData[i]['sub'][j].name)
            }
          }
        }
      },
      addOptionTwo() {
        this.areas = []
        this.area = ''
        for (let i = 0; i < cityData.length; i++) {
          if (cityData[i].name == this.province) {
            for (let j = 0; j < cityData[i]['sub'].length; j++) {
              if (cityData[i]['sub'][j].name == this.city) {
                for (let k = 0; k < cityData[i]['sub'][j]['sub'].length; k++) {
                  this.areas.push(cityData[i]['sub'][j]['sub'][k].name)
                }
              }
            }
          }
        }
      },
      handleAvatarSuccess(res, file) {
        this.userInfo.MemberPersonalPic = res.data
      },
      beforeAvatarUpload(file) {
        console.log(file)
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        // return isJPG && isLt2M;
      },
      checkEmail(value) {
        let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (emailReg.test(value)) {
          return true
        } else {
          return false
        }
      },
      submit() {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
        if (this.userInfo.MemberEmail) {
          if (!this.checkEmail(this.userInfo.MemberEmail)) {
            this.$message('请输入正确邮箱格式')
            return
          }
        }
        var age = null;
        if (this.ages == '00后') {
          age = 1
        }
        if (this.ages == '90后') {
          age = 2
        }
        if (this.ages == '80后') {
          age = 3
        }
        if (this.ages == '70后') {
          age = 4
        }
        if (this.ages == '60后') {
          age = 5
        }
        if (this.ages == '夕阳红') {
          age = 6
        }
        let data = {
          "userID": user.MemberGuid,
          "nickName": this.userInfo.MemberName,
          "headPic": this.userInfo.MemberPersonalPic,
          "sex": this.gender == 1 ? '男' : '女',
          "age": age,
          "email": this.userInfo.MemberEmail,
          "province": this.province,
          "city": this.city,
          "area": this.area,
          "constellation": '',
          "sports": '',
          "characterTags": ''
        }
        UpdateUserInfo(data).then(res => {
          if (res.state == 0) {
            this.$message.success('保存成功')
            this.getPersonalInfo()
          }
        })
      },
      getPersonalInfo() {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
        let data = {
          "userID": user ? user.MemberGuid : '',
          "tabName": ""
        }
        GetPersonalInfo(data).then(res => {
          console.log('res', res)
          if (res.state === 0) {
            this.userInfo = res.data[0]
            localStorage.setItem('user', JSON.stringify(this.userInfo))
            if (res.data[0].MemberSex == '0858fad1-30d9-4abe-9d72-867f907bb8ad') {
              this.gender = 2
            } else {
              this.gender = 1
            }
            if (this.userInfo.MemberAge == 1) {
              this.ages = '00后'
            }
            if (this.userInfo.MemberAge == 2) {
              this.ages = '90后'
            }
            if (this.userInfo.MemberAge == 3) {
              this.ages = '80后'
            }
            if (this.userInfo.MemberAge == 4) {
              this.ages = '70后'
            }
            if (this.userInfo.MemberAge == 5) {
              this.ages = '60后'
            }
            if (this.userInfo.MemberAge == 6) {
              this.ages = '夕阳红'
            }
            this.province = this.userInfo.MemberProvince ? this.userInfo.MemberProvince : ''
            this.city = this.userInfo.MemberCity ? this.userInfo.MemberCity : ''
            this.area = this.userInfo.MemberArea ? this.userInfo.MemberArea : ''
            this.$emit('upDateInfo')
          }
        })
      },
    }
  }

</script>

<style scoped lang="scss">
  .list {
    display: flex;
    margin: 0 30px 40px;
    align-items: center;
    color: #666666;
    font-size: 14px;

    .title {
      margin-right: 40px;
    }

    .avator {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .change {
      margin-left: 25px;
      width: 50px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      background: #F3F3F3;
      border-radius: 4px;
    }
  }

  .options {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .gender {
    color: #333333;
    font-size: 14px;
  }

  .save {
    margin: 50px 30px 0;
    width: 160px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #21D0C8;
    border-radius: 20px;
    color: #fff;
    font-size: 18px;

  }

</style>
<style>
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #C0C4CC
  }

  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #C0C4CC
  }

  .el-select-dropdown__item.selected {
    color: #21D0C8;
  }

  .el-select .el-input__inner:focus {
    border-color: #C0C4CC
  }

</style>
