<template>
  <div class="boxs">
    <div class="topNav">
      <div class="tabbs">
        <div :class="selectIndex=='全部'?'selected':''" @click="choose('全部')">全部</div>
        <div :class="selectIndex=='已完成'?'selected':''" @click="choose('已完成')">已完成</div>
        <div :class="selectIndex=='已退款'?'selected':''" @click="choose('已退款')">已退款</div>
        <div :class="selectIndex=='已关闭'?'selected':''" @click="choose('已关闭')">已关闭</div>
      </div>
    </div>
    <div class="types">
      <div style="width:135px">订单编号</div>
      <div style="width:70px">会员类型</div>
      <div style="width:133px">下单时间</div>
      <div style="width:65px">支付价格</div>
      <div style="width:60px">支付方式</div>
      <div style="width:60px">订单状态</div>
    </div>
    <div class="cont" v-for="(item,index) in list" :key='index'>
      <div style="width:135px" class="hovers">{{item.OrderCustomerId}}</div>
      <div style="width:70px" class="hovers">{{item.OrderTitle}}</div>
      <div style="width:133px" class="hovers">{{item.PayDate}}</div>
      <div style="width:65px">
        <span class="price">¥{{item.OrderRealPay}}</span>
        <div style="font-size:12px" v-if="item.OrderRealPay!=item.OrderNeedPay">原价¥{{item.OrderNeedPay}}</div>
      </div>
      <div style="width:60px" class="hovers">{{item.PayType}}</div>
      <div style="width:60px">{{item.StateName}}</div>
    </div>
    <div class="nomsg" v-if="total==0">
      <img src="../assets/personal_no_order.png" alt="">
      <div>暂无记录</div>
    </div>
  </div>
</template>

<script>
  import {
    GetMemberAssociatorOrderList
  } from '@/api'
  export default {
    data() {
      return {
        selectIndex: '全部',
        tabName: '全部',
        list: [],
        total: 0
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      choose(e) {
        this.selectIndex = e
        this.tabName = e
        this.getList()
      },
      getList() {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "userID": user.MemberGuid,
          "tabName": this.tabName
        }
        GetMemberAssociatorOrderList(data).then(res => {
          if (res.state == 0) {
            let list = res.data.lst;
            this.list = list
            this.total = res.data.count
          }
        })
      }
    }
  }

</script>

<style scoped lang="scss">
  .boxs {
    margin: 30px;
    cursor: default;
  }

  .topNav {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #F3F3F3;

  }

  .tabbs {
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    color: #AAAAAA;

    div {
      border-bottom: 2px solid transparent;
    }

    .selected {
      color: #21D0C8;
      border-color: #21D0C8;
    }
  }

  .types {
    display: flex;
    justify-content: space-between;
    padding: 0 9px;
    margin-top: 9px;
    height: 30px;
    line-height: 30px;
    background: #F3FCFC;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
    box-sizing: border-box;
  }

  .cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 9px;
    border-bottom: 1px solid #F3F3F3;
    font-size: 14px;
    color: #aaa;
  }

  .cont:hover {
    background: #F3F3F3;

    .hovers {
      color: #333;
    }

    .price {
      color: #F24537;
    }
  }

  .nomsg {
    margin-top: 180px;
    height: 100%;
    text-align: center;

    img {
      width: 104px;
      height: 99px;
    }

    div {
      font-size: 14px;
      color: #AAAAAA;
      text-align: center;
    }
  }

</style>
