<template>
  <div class="pages" ref="pages">
    <div class="lists">
      <div :class="serveType=='服务订单'?'seleced':''" @click="typeSelect('服务订单')">服务订单</div>
      <div :class="serveType=='约见律师'?'seleced':''" @click="typeSelect('约见律师')">约见律师</div>
      <div :class="serveType=='案件委托'?'seleced':''" @click="typeSelect('案件委托')">案件委托</div>
    </div>
    <div class="topNav">
      <div class="tabbs">
        <div :class="selectIndex=='全部'?'selected':''" @click="choose('全部')">全部</div>
        <div :class="selectIndex=='待服务'?'selected':''" @click="choose('待服务')">待服务</div>
        <div :class="selectIndex=='服务中'?'selected':''" @click="choose('服务中')">服务中</div>
        <div v-if="serveType=='服务订单'" :class="selectIndex=='待评价'?'selected':''" @click="choose('待评价')">待评价</div>
        <div :class="selectIndex=='已完成'?'selected':''" @click="choose('已完成')">已完成</div>
        <div :class="selectIndex=='已终止'?'selected':''" @click="choose('已终止')">已终止</div>
      </div>
      <div v-if="serveType=='服务订单'">
        <el-select v-model="orderTitle" placeholder="全部服务" size="mini" @change="titleChange">
          <el-option v-for="item in options" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="types">
      <div style="width:130px;margin-right:10px">服务类型</div>
      <div style="flex:1">下单时间</div>
      <div style="width:100px" v-if="serveType=='服务订单' || serveType=='案件委托'">支付价格</div>
      <div :style="{'width':serveType=='约见律师'?'250px':'100px'}">订单状态</div>
      <div style="width:80px" v-if="serveType=='服务订单'">操作</div>
    </div>
    <div class="lis" v-for="(item,index) in list" :key="index">
      <div class="flx">
        <div class="name" style="width:130px;margin-right:10px">{{item.OrderTitle}}</div>
        <div class="name" style="flex:1">{{item.PayDate}}</div>
        <div style="width:100px" v-if="serveType=='服务订单' || serveType=='案件委托'">
          <div class="price" v-if="serveType=='案件委托' &&(item.StateName!='待确认' && item.StateName!='取消')">¥3</div>
          <div class="price" v-if="serveType=='服务订单'">¥{{item.OrderRealPay}}</div>
          <div v-if="serveType=='案件委托' &&(item.StateName!='待确认' && item.StateName!='取消')">案件确认金</div>
        </div>
        <div :style="{color:types==='已完成'?'':'#FF984D',width:serveType=='约见律师'?'250px':'100px'}">{{item.StateName}}
        </div>
        <div class="btns" v-if="serveType=='服务订单' &&orderTitle!='心意' &&item.OrderTitle!='心意'">
          <div class="contact" @click="connect(item.LawyerThirdId,item.StateName)"
            v-if="orderTitle!='合同模板'&&item.OrderTitle!='合同模板'">
            <span>联系律师</span>
            <div class="read" v-if="item.noRead&&item.noRead!=0">{{item.noRead}}</div>
          </div>
          <div class="name" v-if="item.IsPj!=1&&(item.StateName=='已完成'||item.StateName=='服务中')&&item.LawyerThirdId"
            @click="goCommon(item.LawyerGuid,item.LawyerThirdId,item.OrderGuid)">评价律师</div>
          <div class="name" v-if="item.DocUrl" @click="downLoad(item.DocUrl)">下载模板</div>
          <div class="name" v-if="item.InstrumentUrl" @click="downLoad(item.InstrumentUrl)">下载文书</div>
        </div>
        <div v-else-if="serveType=='服务订单'" class="btns"></div>
      </div>
    </div>
    <el-pagination style="margin-top:60px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="PageIndex" :page-size="6" :hide-on-single-page="hidePage" layout=" prev, pager, next,jumper"
      :total="total" v-if="total>0">
    </el-pagination>
    <div class="nomsg" v-if="total==0">
      <img src="../assets/personal_no_order.png" alt="">
      <div>暂无订单</div>
    </div>
    <!-- 评价 -->
    <el-dialog class="wenshu" title="" top="20vh" :show-close="false" :visible.sync="dialogVisibleComment"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px" :append-to-body="true">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleComment=false">
      <div class="title">请您对当前服务进行评价</div>
      <div class="pj">
        <div class="subtitle">对TA的印象</div>
        <div class="star">
          <img
            :src="pj.star>=index+1?require('../assets/popup_btn_shape_selected.png'):require('../assets/popup_btn_Shape_normal.png')"
            alt="" v-for="(item,index) in 5" :key="index" @click="starFun(index)">
        </div>
        <div class="subtitle">印象标签</div>
        <div class="tag">
          <div :class="pj.tag.indexOf(tag[index])>=0?'active':''" v-for="(item,index) in tag" :key="index"
            @click="tagFun(index)">{{item}}</div>
        </div>
        <el-input type="textarea" resize="none" placeholder="请输入评价内容" v-model="pj.msg">
        </el-input>
      </div>
      <div class="btn" @click="addComment">提交评价</div>
    </el-dialog>
    <!-- 评价成功 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisible"
      :close-on-click-modal="false" :close-on-press-escape="false" width="340px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close">
      <div class="success">
        <img src="../assets/login_tick100.png" alt="">
        <div>评价成功</div>
      </div>
      <div class="btn" @click="closePJ">确定</div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    MyOrders,
    AddComment,
    CommentInfo,
    GetInfoByThirdIds,
    LawyerCommentList,
    LawyerServiceInfo,
    AppointmentOrders,
    CaseEntrustmentOrders
  } from '@/api'
  export default {
    data() {
      return {
        options: ['全部服务', '图文咨询', '电话咨询', '视频咨询', '文书服务', '律师函', '私人律师', '合同模板', '心意'],
        value: '全部服务',
        selectIndex: '全部',
        PageIndex: 1,
        total: 0,
        hidePage: true,
        types: '',
        filterStates: '全部',
        orderTitle: '全部服务',
        dialogVisibleOrder: false,
        dialogVisible: false,
        dialogVisibleComment: false,
        list: [],
        pj: {
          msg: '',
          star: 5,
          tag: []
        },
        tag: [],
        tag1: [],
        tag2: [],
        tag3: [],
        lawyerInfo: {},
        newComment: {},
        tag4: [],
        commentGuid: '',
        serveType: '服务订单'
      }
    },
    created() {
      this.getList()
    },
    mounted() {

    },
    methods: {
      getList() {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "memberGuid": user.MemberGuid,
          "isPJ": '',
          "states": '',
          "pageSize": 6,
          "pageNum": this.PageIndex,
          "filterStates": this.filterStates,
          "orderTitle": this.orderTitle
        }
        MyOrders(data).then(res => {
          if (res.state == 0) {
            this.total = res.data.TotalNum
            let list = res.data.serviceOrdersModels
            for (let i = 0; i < list.length; i++) {
              let num = localStorage.getItem(user.MemberThirdId + list[i].LawyerThirdId + '_noreadnum') ?
                localStorage.getItem(user.MemberThirdId + list[i].LawyerThirdId + '_noreadnum') : ''
              list[i].noRead = num
            }
            this.list = list
          }
        })
      },
      typeSelect(type) {
        this.serveType = type
        this.filterStates = '全部'
        this.PageIndex = 1
        this.selectIndex = '全部'
        this.orderTitle = '全部服务'
        if (type == '约见律师') {
          this.getAppoint()
        } else if (type == '案件委托') {
          this.getCase()
        } else {
          this.getList()
        }
      },
      getAppoint() {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "memberGuid": user.MemberGuid,
          "isPJ": '',
          "states": '',
          "pageSize": 6,
          "pageNum": this.PageIndex,
          "filterStates": this.filterStates,
        }
        AppointmentOrders(data).then(res => {
          if (res.state == 0) {
            this.total = res.data.TotalNum
            let list = res.data.serviceOrdersModels
            this.list = list
          }
        })
      },
      getCase() {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "memberGuid": user.MemberGuid,
          "isPJ": '',
          "states": '',
          "pageSize": 6,
          "pageNum": this.PageIndex,
          "filterStates": this.filterStates,
          "orderTitle": this.orderTitle
        }
        CaseEntrustmentOrders(data).then(res => {
          if (res.state == 0) {
            this.total = res.data.TotalNum
            let list = res.data.serviceOrdersModels
            this.list = list
          }
        })
      },
      titleChange(e) {
        this.orderTitle = e
        this.PageIndex = 1
        this.getList()
      },
      choose(e) {
        this.selectIndex = e
        this.filterStates = e
        this.PageIndex = 1
        if (this.serveType == '服务订单') {
          this.getList()
        }
        if (this.serveType == '约见律师') {
          this.getAppoint()
        }
        if (this.serveType == '案件委托') {
          this.getCase()
        }
      },
      handleSizeChange() {

      },
      handleCurrentChange(e) {
        this.PageIndex = e
        if (this.serveType == '服务订单') {
          this.getList()
        }
        if (this.serveType == '约见律师') {
          this.getAppoint()
        }
        if (this.serveType == '案件委托') {
          this.getCase()
        }
      },
      connect(id, state) {
        _hmt.push(['_trackEvent', 'click', '服务订单-联系律师', 'pc']);
        if (state == '待接单') {
          this.$store.dispatch('onChatType', 'waitd')
        } else {
          this.$store.dispatch('onChatType', 'chat')
        }
        localStorage.setItem('chatroomLawyer', id)
        this.$router.push({
          path: '/chatroom'
        })
      },
      addComment() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (this.pj.tag.length == 0) {
          this.$message('请选择1-3个印象标签')
        } else {
          var data = {
            "stars": this.pj.star,
            "commentDes": this.pj.msg == '' ? '暂无文字评价' : this.pj.msg,
            "orderGuid": this.commentGuid,
            "memberGuid": user.MemberGuid,
            "lawyerGuid": this.lawyerInfo.MemberGuid,
            "impressIDs": this.pj.tag.join(',')
          }
          AddComment(data).then(res => {
            if (res.state == 0) {
              this.dialogVisible = true
              this.getList()
            } else {
              this.$message(res.des)
            }
          })
        }
      },
      closePJ() {
        this.dialogVisibleComment = false
        this.dialogVisibleOrder = false
        this.dialogVisible = false
      },
      goCommon(guid, tid, oid) {
        _hmt.push(['_trackEvent', 'click', '服务订单-评价律师', 'pc']);
        this.dialogVisibleComment = true
        this.lawyerInfo.MemberGuid = guid
        this.commentGuid = oid
        this.getInfo(guid, tid)
      },
      getTag(guid) {
        let user = JSON.parse(localStorage.getItem('user'));
        var data = {
          "memberGuid": user.MemberGuid,
          "lawyerGuid": guid
        }
        CommentInfo(data).then(res => {
          if (res.state == 0) {
            var impress = res.data.Impress
            for (var i = 0; i < impress.length; i++) {
              if (impress[i].IsPraise == 1) {
                this.tag1.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 2) {
                this.tag2.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 3) {
                this.tag3.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 4) {
                this.tag4.push(impress[i].ImpressName)
              }
            }
            this.tag = this.getArrayItems(this.tag3, 6)
          }
        })
      },
      getInfo(guid, lawyerThirdId) {
        GetInfoByThirdIds({
          "thirdIds": lawyerThirdId
        }).then(res => {
          if (res.state == 0) {
            LawyerServiceInfo({
              "lawyerGuid": res.data[0].MemberGuid
            }).then(res => {
              if (res.state == 0) {
                res.data.model.MemberRealName = res.data.model.MemberRealName.substring(0, 1) + '律师'
                res.data.model.ServiceCountMonth = res.data.model.ServiceCountMonth == 0 ? parseInt(Math
                  .random() * 100 + 100) : res.data.model.ServiceCountMonth
                res.data.model.Satisfaction = res.data.model.Satisfaction == '0%' ? '100%' : res.data.model
                  .Satisfaction
                this.lawyerInfo = res.data.model
                this.getTag(guid)
              }
            })
            LawyerCommentList({
              "lawyerGuid": guid
            }).then(res => {
              if (res.state == 0) {
                if (res.data.length > 0) {
                  res.data[0].MemberMoblie_kh = res.data[0].MemberMoblie_kh.substring(0, 3) + '****' + res.data[
                    0].MemberMoblie_kh.substring(7, 11)
                  res.data[0].gray_star = 5 - res.data[0].Stars
                  this.newComment = res.data[0]
                }
              }
            })
          }
        })
      },
      getArrayItems: function (arr, num) {
        //新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组;
        var temp_array = new Array();
        for (var index in arr) {
          temp_array.push(arr[index]);
        }
        //取出的数值项,保存在此数组
        var return_array = new Array();
        for (var i = 0; i < num; i++) {
          //判断如果数组还有可以取出的元素,以防下标越界
          if (temp_array.length > 0) {
            //在数组中产生一个随机索引
            var arrIndex = Math.floor(Math.random() * temp_array.length);
            //将此随机索引的对应的数组元素值复制出来
            return_array[i] = temp_array[arrIndex];
            //然后删掉此索引的数组元素,这时候temp_array变为新的数组
            temp_array.splice(arrIndex, 1);
          } else {
            //数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
            break;
          }
        }
        return return_array;
      },
      starFun(index) {
        this.pj.tag = []
        this.pj.star = index + 1
        //IsPraise:1男；2女；3通用；4通用坏
        //1-2星：4；3-4-5星：性别x3+通用x3
        if (this.pj.star == 1 || this.pj.star == 2) {
          this.tag = this.getArrayItems(this.tag4, 6)
        } else if (this.pj.star == 3 || this.pj.star == 4 || this.pj.star == 5) {
          var arr1 = this.getArrayItems(this.tag3, 3),
            arr2 = []
          if (this.lawyerInfo.MemberSex == '0858fad1-30d9-4abe-9d72-867f907bb8ad') {
            arr2 = this.getArrayItems(this.tag2, 3)
          } else {
            arr2 = this.getArrayItems(this.tag1, 3)
          }
          this.tag = [...arr1, ...arr2];
        }
      },
      tagFun(index) {
        if (this.pj.tag.length < 3 && this.pj.tag.indexOf(this.tag[index]) < 0) {
          this.pj.tag.push(this.tag[index])
        } else if (this.pj.tag.indexOf(this.tag[index]) >= 0) {
          this.pj.tag.splice(this.pj.tag.indexOf(this.tag[index]), 1)
        } else if (this.pj.tag.length >= 3) {
          this.$message('印象标签最多选三个')
        }
        console.log(this.pj.tag)
      },
      downLoad(url) {
        let index = url.lastIndexOf('.');
        let filetype = url.slice(index + 1);
        let doc = url.slice(0, index);
        let docIndex = doc.lastIndexOf('/');
        let docName = doc.slice(docIndex + 1);
        if (filetype.toLowerCase() == 'docx' || filetype.toLowerCase() == 'doc') {
          let srcs = 'https://view.officeapps.live.com/op/view.aspx?src=' + url;
          window.open(srcs)
        } else {
          window.open(url)
        }
        var x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.responseType = 'blob';
        x.onload = function (e) {
          if (navigator.msSaveBlob) {
            var name = resourceUrl.substr(resourceUrl.lastIndexOf("/") + 1);
            return navigator.msSaveBlob(x.response, name);
          } else {
            var urls = window.URL.createObjectURL(x.response)
            var a = document.createElement('a');
            a.href = urls;
            a.download = docName;
            a.click();
          }
        }
        x.send();
      }
    }
  }

</script>

<style scoped lang="scss">
  .pages {
    margin: 30px;
    cursor: default;
  }

  .lists {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-size: 14px;
    color: #666666;

    div {
      margin-right: 20px;
      width: 80px;
      height: 30px;
      line-height: 30px;
      background: #F3F3F3;
      border-radius: 10px;
      text-align: center;
    }

    div:hover {
      opacity: 0.7;
    }

    .seleced {
      color: #fff;
      background: #21D0C8;
    }
  }

  .topNav {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #F3F3F3;

  }

  .tabbs {
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    color: #AAAAAA;

    div {
      border-bottom: 2px solid transparent;
    }

    .selected {
      color: #21D0C8;
      border-color: #21D0C8;
    }
  }

  .types {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 9px;
    padding: 0 20px;
    height: 30px;
    background: #F3FCFC;
    color: #333333;
    font-size: 14px;
    box-sizing: border-box;
  }

  .lis {
    padding: 25px 0;
    font-size: 14px;
    color: #AAAAAA;
    border-bottom: 1px solid #F3F3F3;
    box-sizing: border-box;

    .flx {
      padding: 0 20px;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      box-sizing: border-box;
    }

    .more {
      text-align: right;
    }
  }

  .lis:hover {
    background: #F3F3F3;

    .name {
      color: #333333;
    }

    .price {
      color: #F24537;
    }
  }

  .btns {
    width: 80px;

    div {
      margin-bottom: 7px;
      width: 70px;
      height: 24px;
      text-align: center;
      line-height: 25px;
    }

  }

  .contact {
    position: relative;
    color: #fff;
    background: #21D0C8;
    border-radius: 6px;

    .read {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #F24537;
      color: #fff;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
    }
  }

  .nomsg {
    margin-top: 180px;
    height: 100%;
    text-align: center;

    img {
      width: 104px;
      height: 99px;
    }

    div {
      font-size: 14px;
      color: #AAAAAA;
      text-align: center;
    }
  }

  .wenshu {
    border-radius: 10px !important;

    .close {
      position: absolute;
      top: 0;
      right: 0;
    }

    .title {
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-bottom: 28px;
    }

    .info {
      .item {
        margin-bottom: 10px;
        display: flex;

        >div {
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          color: #666666;
        }

        >div:nth-child(1) {
          width: 70px;
        }

        >div:nth-child(2) {
          color: #aaa;
          margin-left: 20px;
          flex: 1;
        }
      }
    }

    .btn {
      width: 160px;
      height: 40px;
      background: #00C8B4;
      border-radius: 20px;
      margin: 28px auto 0;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      ;
      cursor: pointer;
    }

    .mintip {
      font-size: 18px;
      color: #666666;
      width: 216px;
      margin: 40px auto 40px;
      text-align: center;
    }

    .webtip {
      font-size: 18px;
      color: #666666;
      margin: 40px auto 10px;
      text-align: center;
    }

    .webtip_orange {
      font-size: 14px;
      color: #FF9900;
      margin: 0px auto 40px;
      text-align: center;
    }

    .list {
      height: 400px;

      .item {
        background: #FFFFFF;
        border: 1px solid #E9EEF4;
        border-radius: 6px;
        padding: 15px 20px;
        width: 330px;
        cursor: pointer;
        position: relative;
        margin: 0 auto 20px;
        box-sizing: border-box;

        >div:nth-child(1) {
          font-size: 16px;
          color: #333333;
          margin-bottom: 10px;
        }

        >div:nth-child(2) {
          font-size: 14px;
          color: #666;
          margin-bottom: 2px;
        }

        >div:nth-child(3) {
          font-size: 14px;
          color: #666;
        }

        img {
          position: absolute;
          bottom: -1px;
          right: -1px;
        }
      }

      .item.active {
        border: 1px solid #00C8B4;
      }
    }

    .pj {
      text-align: center;

      .subtitle {
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
      }

      .star {
        padding-bottom: 20px;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 26px;

        img {
          margin-right: 10px;
          cursor: pointer;
        }

        img:last-child {
          margin-right: 0;
        }
      }

      .tag {
        padding-bottom: 20px;
        cursor: pointer;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 26px;

        >div {
          display: inline-block;
          padding: 2px 8px;
          background: #DEDEDE;
          border-radius: 12px;
          font-size: 14px;
          color: #666666;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .active {
          color: #fff;
          background: #00C8B4;
        }
      }
    }

    .success {
      text-align: center;
      font-size: 18px;
      color: #666666;

      img {
        margin-bottom: 10px;
      }
    }
  }

  .big {
    position: fixed;
    width: 500px;
    top: 100px;
    left: 50%;
    margin-left: -250px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0);
    height: 80%;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
    }
  }

</style>
<style lang="scss">
  .pj {
    .el-dialog__body {
      padding: 35px 0px;
    }
  }

  .pages .el-dialog {
    border-radius: 10px;
  }

</style>
