<template>
  <div class="mu">
    <div class="topNav">
      <div class="tabbs">
        <div :class="selectIndex=='已购买'?'selected':''" @click="choose('已购买')">已购买</div>
        <div :class="selectIndex=='已下载'?'selected':''" @click="choose('已下载')">已下载</div>
      </div>
    </div>
    <div class="m_box">
      <div class="cover" v-for="(item,index) in list" :key="index">
        <div class="kuang">
          <img :src="item.DocPreviewPic?item.DocPreviewPic:require('../assets/1.png')" alt="">
          <div class="down">
            <div @click="downMould(item.DocGuid)">下载</div>
          </div>
        </div>
        <div class="name">{{item.DocName}}</div>
      </div>
    </div>
    <div class="nomsg" v-if="list.length==0">
      <img src="../assets/personal_no_order.png" alt="">
      <div>暂无模板</div>
    </div>
  </div>
</template>

<script>
  import {
    GetUserCenterContractTemplates
  } from '@/api'
  export default {
    data() {
      return {
        selectIndex: '已购买',
        showDown: false,
        list: [],
        user: null
      }
    },
    mounted() {
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.getList()
    },
    methods: {
      choose(e) {
        this.selectIndex = e
        this.getList()
      },
      getList() {
        var data = {
          "userID": this.user.MemberGuid,
          "tabName": this.selectIndex,
        }
        GetUserCenterContractTemplates(data).then(res => {
          if (res.state == 0) {
            this.list = res.data
          }
        })
      },
      downMould(guid) {
        this.$router.push('/contractDetail?guid=' + guid)
      }
    }
  }

</script>

<style scoped lang="scss">
  .mu {
    margin: 30px 0 30px 30px;
    cursor: default;
  }

  .topNav {
    display: flex;
    margin-right: 30px;
    margin-bottom: 14px;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #F3F3F3;

  }

  .tabbs {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    color: #AAAAAA;

    div {
      border-bottom: 2px solid transparent;
    }

    .selected {
      color: #21D0C8;
      border-color: #21D0C8;
    }
  }

  .m_box {
    display: flex;
    flex-wrap: wrap;
    align-items: space-between;
  }

  .kuang {
    position: relative;
    width: 148px;
    height: 210px;
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    .down {
      display: none;
    }
  }

  .kuang:hover {
    .down {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);

      div {
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        font-size: 14px;
        background: #21D0C8;
        border-radius: 4px;
      }
    }
  }

  .name {
    margin-top: 10px;
    width: 148px;
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cover {
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .nomsg {
    margin-top: 180px;
    height: 100%;
    text-align: center;

    img {
      width: 104px;
      height: 99px;
    }

    div {
      font-size: 14px;
      color: #AAAAAA;
      text-align: center;
    }
  }

</style>
