<template>
  <div class="quan">
    <div class="topNav">
      <div class="tabbs">
        <div :class="selectIndex=='可使用'?'selected':''" @click="choose('可使用')">可使用</div>
        <div :class="selectIndex=='已失效'?'selected':''" @click="choose('已失效')">已失效</div>
      </div>
      <div class="rules" @click="showRules=true">使用规则</div>
    </div>
    <div class="types">
      <div style="width:105px">优惠券</div>
      <div style="width:100px">有效期</div>
      <div style="width:70px">金额</div>
      <div style="width:110px">使用限制</div>
      <div style="width:60px">操作</div>
    </div>
    <div class="lists" v-for="(item,index) in list" :key="index">
      <div style="width:105px">{{item.CouponsName}}</div>
      <div style="width:100px">{{item.EndDate}}</div>
      <div class="price" style="width:70px">¥{{item.Price==0?'免费':item.Price}}</div>
      <div style="width:110px">
        <div>{{item.MemberTitle}}</div>
        <div class="des">{{item.CouponType}}</div>
      </div>
      <div style="width:60px">
        <div class="use" v-if="selectIndex=='可使用'" @click="goUse(item.CouponsType)">去使用</div>
        <div v-else>{{item.UseState}}</div>
      </div>
    </div>
    <div class="mark" v-if="showRules">
      <div class="m_box">
        <el-scrollbar style="height:100%;">
          <div style="margin:0 30px;padding-bottom:30px">
            <div class="c_roules">优惠券使用规则</div>
            <div>1、问：优惠券可以兑换现金吗？</div>
            <div>答：不可以。优惠券只能用于抵扣服务费用，不可兑换现金。</div>
            <div>2、问：优惠券在使用期限上有什么限制？</div>
            <div>答：优惠券只能在标示出的有效期内使用，超过有效期的则过期失效。</div>
            <div>3、问：优惠券的用途是什么？</div>
            <div>答：优惠券会标注限制服务的类型，如咨询券只可用于抵扣当次咨询所需支付的费用，未标注限制条件的则可通用。</div>
            <div>4、问：优惠券能不能叠加使用？</div>
            <div>答：当次咨询只能使用一张优惠券，不可叠加使用。</div>
            <div>5、问：优惠券差额部分可以退还吗？</div>
            <div>答：使用的优惠券金额超过订单所需支付的差额部分并不退还。</div>
            <div>6、问：同一天能够使用多少张优惠券？</div>
            <div>答：同一天内多次使用的，使用优惠券共计不得超过三张。</div>
            <div>7、问：一账号内的优惠券能否转让或代为支付？</div>
            <div>答：账户内的优惠券只可使用于本账户内的订单，不得转让或代为支付另一 账户内的订单。</div>
          </div>
        </el-scrollbar>
        <img @click="showRules=false" class="close" src="../assets/popup_icon_close2.png" alt="">
      </div>
    </div>
    <div class="nomsg" v-if="list.length==0">
      <img src="../assets/personal_no_order.png" alt="">
      <div>暂无优惠券</div>
    </div>
    <div class="barcode" v-if="showCode">
      <div class="showcode">
        <img @click="showCode=false" class="closes" src="../assets/icon_close.png" alt="">
        <div class="wentip">温馨提示</div>
        <img class="ercode" src="../assets/ilvdo.jpg" alt="">
        <div class="continu">扫码进入小程序 继续咨询</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    YHList
  } from '@/api'
  export default {
    data() {
      return {
        selectIndex: '可使用',
        showRules: false,
        list: [],
        showCode: false
      }
    },
    created() {
      this.getList()
    },
    methods: {
      choose(e) {
        this.selectIndex = e
        this.getList()
      },
      getList() {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          "memberGuid": user.MemberGuid,
          "filter": this.selectIndex,
          "type": ""
        }
        YHList(data).then(res => {
          if (res.state == 0) {
            this.list = res.data
          }
        })
      },
      goUse(type) {
        _hmt.push(['_trackEvent', 'click', '优惠券-去使用', 'pc']);
        let id = 1;
        if (type == '电话') {
          id = 2
        }
        if (type == '会员') {
          this.$router.push({
            path: '/member',
          })
          return
        }
        if (type == '代金券' || type == '文书代写' || type == '律师函') {
          this.showCode = true
          return
        }
        if (type == '私人律师') {
          this.showCode = true
          return
        }
        this.$router.push({
          path: '/ask',
          query: {
            id
          }
        })
      }
    }
  }

</script>

<style scoped lang="scss">
  .quan {
    margin: 30px;
    cursor: default;
  }

  .topNav {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #F3F3F3;

  }

  .tabbs {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    color: #AAAAAA;

    div {
      border-bottom: 2px solid transparent;
    }

    .selected {
      color: #21D0C8;
      border-color: #21D0C8;
    }
  }

  .rules {
    font-size: 14px;
    color: #aaa;
  }

  .types {
    display: flex;
    justify-content: space-between;
    padding-left: 26px;
    margin-top: 9px;
    height: 30px;
    line-height: 30px;
    background: #F3FCFC;
    border-radius: 4px;
    color: #333333;
    font-size: 14px;
    box-sizing: border-box;
  }

  .lists {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 10px 30px 25px;
    font-size: 14px;
    color: #AAAAAA;
    box-sizing: border-box;
    border-bottom: 1px solid #F3F3F3;
  }

  .lists:hover {
    color: #333;
    background: #F3F3F3;

    .price {
      color: #F24537;
    }
  }

  .des {
    font-size: 12px;
  }

  .use {
    width: 70px;
    height: 24px;
    text-align: center;
    line-height: 25px;
    color: #fff;
    background: #21D0C8;
    border-radius: 6px;
  }

  .mark {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.6);

    .m_box {
      position: relative;
      width: 490px;
      height: 310px;
      background: #FFFFFF;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }

  .c_roules {
    padding-top: 35px;
    margin-bottom: 19px;
    color: #333;
    font-size: 18px;
    text-align: center;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
  }

  .nomsg {
    margin-top: 180px;
    height: 100%;
    text-align: center;

    img {
      width: 104px;
      height: 99px;
    }

    div {
      font-size: 14px;
      color: #AAAAAA;
      text-align: center;
    }
  }

  .barcode {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.7);

    .showcode {
      position: relative;
      width: 340px;
      height: 300px;
      background: #FFFFFF;
      border-radius: 10px;
      overflow: hidden;

      .closes {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
      }

      .wentip {
        font-size: 18px;
        color: #333333;
        text-align: center;
        padding-top: 36px;
      }

      .ercode {
        display: block;
        width: 140px;
        height: 140px;
        margin: 25px auto 10px;
      }

      .continu {
        margin: auto;
        width: 120px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        text-align: center;
      }
    }
  }

</style>
